<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
    <div class="row align-items-center">
      <div class="col-lg-4">
        <div class="page-header  mb-md-0">
            <h4 class="page-title">Billings</h4>
        </div>
      </div>

      <div class="col-lg-5 ms-auto ">
          <div class="d-md-flex d-grid gap-3 justify-content-md-end">
            <button type="button" v-b-modal.modal-prevent-closing class="btn btn-gradient-primary btn-fw">Create New Bill</button>
          </div>
      </div>
    </div>
    <br/>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
            <div class="row">
                <div class="col-lg-4 mb-lg-0 mb-2">
                   <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchbillis" id="searchbillis" autocomplete="off" v-on:keyup="getBillings(1)">
                </div>

                <div class="col-lg-1"></div>
                <div class="col-lg-2  align-self-end mb-lg-0 mb-2">
                    <date-range-picker
                        ref="picker"
                        :opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                        :single-date-picker="false"
                        :showDropdowns="showDropdowns"
                        :show-week-numbers= "false"
                        :time-picker="false"
                        :autoApply="true"
                        v-model="dateRange"
                        :linked-calendars="true"
                        :dateFormat="dd-mmm-yyyy"
                        @finish-selection=getBillings(1)
                        @update=getBillings(1)
                    />
                </div>
                <div class="col-lg-2 align-self-end mb-lg-0 mb-2">
                    <multiselect
                        v-model="filter_status"
                        :options="status_list"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true" placeholder="Payment Status"
                        :preselect-first="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        label="name"
                        @input=getBillings(1)
                    /></multiselect>
                </div>
                <div class="col-lg-3 align-self-end mb-lg-0 mb-2">
                    <multiselect
                        v-model="filter_payment_method"
                        :options="payment_method_list"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true" placeholder=""
                        :preselect-first="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        label="name"
                        @input=getBillings(1)
                    /></multiselect>
                </div>
            </div>

              <br>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Bill No.</th>
                        <th>Patient Number</th>
                        <th>Bill Date</th>
                        <th>Amount (<span v-html="currency"></span>)</th>
                        <th>Discount (<span v-html="currency"></span>)</th>
                        <th>Waived Off Amount (<span v-html="currency"></span>)</th>
                        <th>SGST (<span v-html="currency"></span>)</th>
                        <th>CGST (<span v-html="currency"></span>)</th>
                        <th>IGST (<span v-html="currency"></span>)</th>
                        <th>Payment Status</th>
                        <th>Payment Method</th>
                        <th>Tran. Id</th>
                        <th>Note</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(bill,i) in billings" :key="i" @click="openBillDetails(bill.payment_status, bill.bill_no, bill.patient_number)">
                        <td @click="openBillDetails(bill.payment_status, bill.bill_no, bill.patient_number)">{{bill.bill_no}}</td>
                        <td><router-link :to="{name:'patientsdetails',params:{'pid':bill.patient_number}}">{{bill.patient_number}}</router-link></td>
                        <td>{{bill.billing_date}}</td>
                        <td><span v-html="currency"></span> {{bill.amount}}</td>
                        <td><span v-html="currency"></span> {{bill.discount}}</td>
                        <td><span v-html="currency"></span> {{bill.waived_off_amount}}</td>
                        <td><span v-html="currency"></span> {{bill.sgst}}</td>
                        <td><span v-html="currency"></span> {{bill.cgst}}</td>
                        <td><span v-html="currency"></span> {{bill.igst}}</td>
                        <td><label v-bind:class="{
                                                      'badge badge-success': bill.payment_status === 'Received',
                                                      'badge badge-danger': bill.payment_status === 'Hold',
                                                      'badge badge-info': bill.payment_status === 'Pending',
                                                      'badge badge-warning': bill.payment_status === 'Waived Off'}
                                                      ">{{bill.payment_status}}</label</td>
                        <td>{{bill.payment_method}}</td>
                        <td>{{bill.transaction_id}}</td>
                        <td>{{bill.note}}</td>
                        <td>{{moment.parseZone(bill.created_at).format('DD, MM YYYY')}}</td>
                        <td>{{moment.parseZone(bill.updated_at).format('DD, MM YYYY')}}</td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && billings.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted mb-2 d-inline-block">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getBillings(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
    <b-modal id="modal-prevent-closing" ref="modal" title="" @ok="createBill"  centered>
      <b-form-input v-model="patientno" placeholder="Enter patient number"></b-form-input>
      <p v-if="errormsg != ''" class="text-danger"><br/>{{errormsg}}</p>
      <div v-if="is_API_call_running_get_patient_bill" class="text-center mt-5">
        <b-spinner label=""></b-spinner>
      </div>
    </b-modal>
    <BillingDetailsModel ref="billingDetailsComponent"/>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import BillingDetailsModel from "../model/billing_details"
import {get_user_settings } from '../../helper/helper';

export default {
  name:'billings',
  title: string.PAGE_TITLE_BILLINGS,
  components: {
      NavBar,
Footer,
      SideBar,
      DateRangePicker,
      Multiselect,
      BillingDetailsModel
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)

    return {
      billings:[],
      moment: moment,
      is_API_call_running:false,
      is_API_call_running_get_patient_bill:false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage: 'No Data Found.',
      errormsg: '',
      status_list:[{"id":"All", "name": "All"}, {"id":"0", "name": "Pending"}, {"id":"1", "name": "Received"}, {"id":"2", "name": "Hold"}, {"id":"3", "name": "Waived Off"}],
      payment_method_list:[{"id":"All", "name": "All"}, {"id":"0", "name": "Cash"}, {"id":"1", "name": "Credit or Debit Card"}, {"id":"2", "name": "Net Banking"}, {"id":"3", "name": "UPI"}, {"id":"4", "name": "Insurance"}],
      filter_status: {"id":"All", "name": "All"},
      filter_payment_method: {"id":"All", "name": "All"},
      dateRange:{
        startDate: today,
        endDate: today
      },
      MAXDATE:maxDate,
      patientno: '',
      is_listing_billing: false,
      currency: '',
    }
  },
  mounted() {
    this.getBillings(this.activePage);
    this.currency = get_user_settings().currency_symbol
  },
  methods:{
    ...mapActions("hospital",["getBillingData", "getPatientBillingData", "payPatientBillingData","paidPatientBillingData"]),

    getBillings(page){
        this.activePage = page;
        clearTimeout(this.myTimer)
        this.myTimer = setTimeout(() => {
            this.is_API_call_running = true;
                  this.billings = [];
                  var bodyFormData = new FormData();
                  bodyFormData.append('search_text', this.searchtext);
                  bodyFormData.append('page', page);
                  bodyFormData.append('filter_status', this.filter_status.id);
                  bodyFormData.append('filter_payment_method', this.filter_payment_method.id);
                  bodyFormData.append('filter_from_date', moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'));
                  bodyFormData.append('filter_to_date', moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'));
                  this.getBillingData(bodyFormData).then((response) => {
                    this.is_API_call_running = false;
                    if (response.response_code == 200) {
                      this.billings = response.data;
                      this.listPages = response.no_of_pages;
                    }else{
                      this.errorMessage = response.message;
                    }
                  });
        }, 500)
    },

    previewClick(){
              this.activePage--;
              if(this.activePage >= 1){
                this.getBillings(this.activePage);
              }else{
                this.activePage = 1;
              }
            },

    nextClick(){
        this.activePage++;
        if(this.activePage <= this.listPages){
           this.getBillings(this.activePage);
        }else{
           this.activePage = this.listPages;
        }
    },

    createBill(bvModalEvent) {
        bvModalEvent.preventDefault();
        this.errormsg = ''
        this.is_API_call_running_get_patient_bill = true
        if(this.patientno){
            var bodyFormData = new FormData();
            bodyFormData.append('patient_number', this.patientno);
            this.getPatientBillingData(bodyFormData).then((response) => {
                this.is_API_call_running_get_patient_bill = false
                if (response.response_code == 200) {
                    this.openBillingDetailsModel(response.data)
                }else{
                    this.errormsg = response.message;
                }
            });
        }
    },

    createPatientBill(patient_number) {
        var bodyFormData = new FormData();
        bodyFormData.append('patient_number', patient_number);
        this.getPatientBillingData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.openBillingDetailsModel(response.data)
            } else{
                this.errormsg = response.message;
            }
        });
    },

    getPatientBill(bill_no) {
        var bodyFormData = new FormData();
        bodyFormData.append('bill_no', bill_no);
        this.paidPatientBillingData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.openBillingDetailsModel(response.data)
            }else{
                this.$toasted.error(response.message, {duration: 2000,});
            }
        });
    },

    openBillingDetailsModel(bill_details) {
        this.$refs.billingDetailsComponent.clearData();
        this.$refs.billingDetailsComponent.receiveData(bill_details);
        this.$refs.billingDetailsComponent.show();
        if(this.is_listing_billing == false){
            this.is_listing_billing = true
            var myParent = this
            this.$refs.billingDetailsComponent.$on("hide", function () {
                myParent.getBillings(myParent.activePage);
            });
        }
    },

    openBillDetails(payment_status, bill_no, patient_number) {
        if(payment_status === "Pending" || payment_status === "Hold") {
            this.createPatientBill(patient_number)
        } else {
            this.getPatientBill(bill_no)
        }
    }
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>