<template>
  <b-modal ref="modal2" id="modal2" title="Billing Details" centered size="huge" hide-footer>

    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <h5>Patient Details</h5>
                <p class="mt-3">Patient Number: <small>{{bill_details.patient_details.patient_number}}</small></p>
                <p>Name: <small>{{bill_details.patient_details.patient_name}}</small></p>
                <p>Mo. No.: <small>{{bill_details.patient_details.patient_mobile_number}}</small></p>
                <p>Gender(Age): <small>{{bill_details.patient_details.patient_gender}}({{bill_details.patient_details.patient_age}})</small></p>
                <p>Address: <small>{{bill_details.patient_details.patient_address}}</small></p>
                <p>City: <small>{{bill_details.patient_details.patient_city}}</small></p>
            </div>
        </div>
        <div class="col-md-6 text-start text-md-end text-lg-end mt-5 mt-lg-0 mt-md-0">
            <div class="row">
                <h5>Billing Details</h5>
                <p class="mt-3">Bill Number: <small>{{bill_details.bill_details.billing_no}}</small></p>
                <p>Date: <small>{{bill_details.bill_details.billing_date}}</small></p>
                <p v-if="bill_details.bill_details.admission_date">Admission Date: <small>{{bill_details.bill_details.admission_date}}</small></p>
                <p v-else>Admission Date: <small>-</small></p>
                <p v-if="bill_details.bill_details.discharge_date">Discharge Date: <small>{{bill_details.bill_details.discharge_date}}</small></p>
                <p v-else>Admission Date: <small>-</small></p>
                <p v-if="bill_details.bill_details.room_bed">Room(Bed): <small>{{bill_details.bill_details.room_bed}}</small></p>
                <p v-else>Admission Date: <small>-</small></p>
                <p>Total Amount: <small><strong><span v-html="bill_details.bill_details.currency.currency_symbol"></span> {{bill_details.bill_details.total_payable_mount}}</strong></small></p>
            </div>
        </div>
    </div>
    <br/><br/>
    <h5>Short Summary</h5>

    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <td>Sr. No</td>
                    <td>Particulars</td>
                    <td>Description</td>
                    <td>Amount</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(bill,i) in bill_details.short_summary" :key="i">
                    <td>{{bill.no}}</td>
                    <td>{{bill.particulars}}</td>
                    <td>{{bill.particulars_description}}</td>
                    <td><span v-html="bill.currency.currency_symbol"></span> {{bill.amount}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <br/><br/>
    <h5>Detailed Summary</h5>

    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <td>Sr. No</td>
                    <td>Date</td>
                    <td>Type</td>
                    <td>Particulars</td>
                    <td>Desc.</td>
                    <td>Qty.</td>
                    <td>Qty.Desc.</td>
                    <td>Price</td>
                    <td>Amount</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(bill,i) in bill_details.detailed_summary" :key="i">
                    <td>{{bill.no}}</td>
                    <td>{{bill.date_time}}</td>
                    <td>{{bill.particulars_type}}</td>
                    <td>{{bill.particulars}}</td>
                    <td>{{bill.particulars_description}}</td>
                    <td><b>{{bill.qty}}</b></td>
                    <td>{{bill.qty_desc}}</td>
                    <td><span v-html="bill.currency.currency_symbol"></span> {{bill.rate}}</td>
                    <td><b><span v-html="bill.currency.currency_symbol"></span> {{bill.amount}}</b></td>
                </tr>
            </tbody>
        </table>
    </div>

    <br/><br/>
    <div class="row">
        <div class="col-6 col-lg-3 col-md-3 mt-1">
            <h5>Collect Payments</h5>
        </div>
        <div class="col-1 col-lg-6 col-md-6"/>
        <div class="col-5 col-lg-3 col-md-3">
            <multiselect v-model="$v.typeform.payment_of.$model"
                :options="payment_of_list" :multiple="false" :close-on-select="true" label="name"
                :clear-on-select="false" :preserve-search="true" placeholder="Payment For"
                :preselect-first="false" :showLabels="false"  @input=update_total_payable() :disabled="!payment_pending"></multiselect>
        </div>
    </div>

    <br/>

    <div class="row">
        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">CGST</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <div class="input-group">
                        <div class="input-group-append">
                            <span class="input-group-text text-primary" v-html="currency"></span>
                        </div>
                        <input type="number" v-model="$v.typeform.cgst.$model" class="form-control" name="CGST" placeholder="CGT"  v-on:keyup="update_total_payable()" :disabled="!payment_pending"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
               <label class="col-4 col-lg-12 col-md-12 col-form-label">SGST</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <div class="input-group">
                        <div class="input-group-append">
                            <span class="input-group-text text-primary" v-html="currency"></span>
                        </div>
                        <input type="number" v-model="$v.typeform.sgst.$model" class="form-control" name="SGST" placeholder="SGT"  v-on:keyup="update_total_payable()" :disabled="!payment_pending"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">IGST</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <div class="input-group">
                        <div class="input-group-append">
                            <span class="input-group-text text-primary" v-html="currency"></span>
                        </div>
                    <input type="number" v-model="$v.typeform.igst.$model" class="form-control" name="IGST" placeholder="IGT"  v-on:keyup="update_total_payable()" :disabled="!payment_pending"/>
                    </div>
                </div>
            </div>
        </div>
        <div class=col-md-3 mb-3 mb-lg-0 mb-md-0>
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Notes</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <textarea rows="1" type="text"
                        class="col-8 col-lg-12 col-md-12 form-control"
                        v-model="$v.typeform.note.$model"
                        name="Notes" placeholder="Notes"
                        :class="{ 'is-invalid':  $v.typeform.note.$error }" :disabled="!payment_pending"/>
                </div>
            </div>
        </div>
    </div>
    <br/>
    <div class="row">
        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Receivable Amount</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <div class="input-group">
                        <div class="input-group-append">
                            <span class="input-group-text text-primary" v-html="currency"></span>
                        </div>
                        <input type="number" v-model="total_receviable_amount" class="form-control" name="Amount" placeholder="Amount" disabled/>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Discount</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <div class="input-group">
                        <div class="input-group-append">
                            <span class="input-group-text text-primary" v-html="currency"></span>
                        </div>
                        <input type="number" v-model="$v.typeform.discount.$model" class="form-control" name="Discount" placeholder="Discount"  v-on:keyup="update_total_payable()" :disabled="!payment_pending"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Waive Off Amount</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <div class="input-group">
                        <div class="input-group-append">
                            <span class="input-group-text text-primary" v-html="currency"></span>
                        </div>
                        <input type="number" v-model="$v.typeform.waived_off_amount.$model" class="form-control" name="waived_off_amount" placeholder="Waived Off Amount"  v-on:keyup="update_total_payable()" :disabled="!payment_pending"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Collected By</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <multiselect v-model="$v.typeform.paid_to.$model"
                        :options="paid_to_list" :multiple="false" :close-on-select="true" label="name"
                        :clear-on-select="false" :preserve-search="true" placeholder="Paid To"
                        :preselect-first="false" :showLabels="false" :disabled="!payment_pending"></multiselect>
                </div>
            </div>
        </div>
    </div>

    <br/>
    <div class="row mt-3 mb-3">
        <div class="row text-md-end text-lg-end">
            <div class="col-ms-12">
                <p><small>Total Receivable: </small><span v-html="currency"></span> {{total_receviable_amount}}</p>
                <p><small> CGST: </small><span v-html="currency"></span> {{typeform.sgst}}</p>
                <p><small> SGST: </small><span v-html="currency"></span> {{typeform.cgst}}</p>
                <p><small> IGST: </small><span v-html="currency"></span> {{typeform.igst}}</p>
                <p class="text-danger" v-if="typeform.discount && typeform.discount != '0'"><small> Discount: </small><span v-html="currency"></span> {{typeform.discount}}</p>
                <p class="text-danger" v-if="typeform.waived_off_amount && typeform.waived_off_amount != '0'"><small> Waived Off: </small><span v-html="currency"></span> {{typeform.waived_off_amount}}</p>
                <h6><small> Actual Receivable: </small><span v-html="currency"></span> {{actual_receviable_amount}}</h6>
            </div>
        </div>
    </div>

    <br/>
    <div class="row">
        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Bill Date</label>
        		<div class="form-control">
                    <DatePicker class="date-picker"
                                v-model="$v.typeform.bill_date.$model"
                                format="DD-MM-YYYY"
                                placeholder="Date of Birth"
                                :disabled-date="disableDatesAfterMax"
                                :disabled="!payment_pending"/>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Payment Status</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <multiselect v-model="$v.typeform.payment_status.$model"
                        :options="payment_status_list" :multiple="false" :close-on-select="true" label="name"
                        :clear-on-select="false" :preserve-search="true" placeholder="Payment Status"
                        :preselect-first="false" :showLabels="false" :disabled="!payment_pending"></multiselect>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Paid Via</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <multiselect v-model="$v.typeform.payment_method.$model"
                        :options="payment_method_list" :multiple="false" :close-on-select="true" label="name"
                        :clear-on-select="false" :preserve-search="true" placeholder="Payment Method"
                        :preselect-first="false" :showLabels="false" :disabled="!payment_pending"></multiselect>
                </div>
            </div>
        </div>
        <div class=col-md-3>
            <div class="row">
                <label class="col-4 col-lg-12 col-md-12 col-form-label">Transaction No.</label>
                <div class="col-8 col-lg-12 col-md-12 ">
                    <input type="text" v-model="$v.typeform.transaction_id.$model" class="form-control" name="Transaction No" placeholder="Transaction No" :disabled="!payment_pending"/>
                </div>
            </div>
        </div>
    </div>

    <br/><br/>
    <div class="row" v-if="payment_pending">
        <div class="col-md-6"></div>
        <div class="col-md-6">
            <div class="row">
                <button type="button" class="col-md-5 btn btn-outline-secondary me-2" @click="hide()">Close</button>
                <button v-if="!is_btn_spinner" type="button" class="col-md-6 btn btn-gradient-primary" @click="receiveAmount()">Amount Received</button>
                <b-button v-else class="col-md-6" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                        Loading...
                </b-button>
            </div>
        </div>
    </div>

  </b-modal>
</template>
<script>
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import Swal from 'sweetalert2'
import string from "../../constant/strings.js"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    components: {
      Multiselect,
      DatePicker,
    },
    data() {
        return {
          moment,
          bill_details:null,
          payment_status:{"id":"1", "name": "Received"},
          payment_method:null,
          payment_of:null,
          paid_to:null,
          total_receviable_amount:null,
          payment_status_list:[{"id":"0", "name": "Pending"}, {"id":"1", "name": "Received"}, {"id":"2", "name": "Hold"}, {"id":"3", "name": "Waived Off"}],
          payment_method_list:[{"id":"0", "name": "Cash"}, {"id":"1", "name": "Credit or Debit Card"}, {"id":"2", "name": "Net Banking"}, {"id":"3", "name": "UPI"}, {"id":"4", "name": "Insurance"}],
          payment_of_list:[{"id":"0", "name": "All"}, {"id":"1", "name": "OPD and IPD"}, {"id":"2", "name": "Reports"}, {"id":"3", "name": "Medicine opd and ipd"}],
          paid_to_list : [{"id": "0", "name":"Hospital"}, {"id": "1", "name":"Laboratory"}, {"id": "2", "name":"Medical"}, {"id": "3", "name":"Hospital Account"}],
          typeform: {
            sgst:'0',
            cgst:'0',
            igst:'0',
            note:'',
            bill_date:new Date(),
            amount:'',
            discount:'',
            transaction_id:'',
            waived_off_amount:'',
            payment_status:{"id":"1", "name": "Received"},
            payment_method:null,
            payment_of:null,
            paid_to:null,
          },
          actual_receviable_amount: 0,
          is_btn_spinner:false,
          currency:'',
          payment_pending:true,
        }
    },
    validations: {
        typeform: {
            sgst:{},
            cgst:{},
            igst:{},
            note:{},
            bill_date:{required},
            amount:{required},
            discount:{required},
            transaction_id:{required},
            waived_off_amount:{required},
            payment_status:{required},
            payment_method:{required},
            payment_of:{required},
            paid_to:{required},
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions("hospital",["payPatientBillingData"]),

        show() {
            this.$refs.modal2.show();
            this.payment_pending = true
            this.update_total_payable()
            this.currency = this.bill_details.short_summary[0].currency.currency_symbol
            this.typeform.bill_date = new Date(this.bill_details.bill_details.billing_date)
            if(this.bill_details.bill_details.payment_status == "1" || this.bill_details.bill_details.payment_status == "3") {
                this.typeform.payment_of = this.bill_details.bill_details.payment_of
                this.typeform.cgst = this.bill_details.bill_details.cgst
                this.typeform.sgst = this.bill_details.bill_details.sgst
                this.typeform.igst = this.bill_details.bill_details.igst
                this.typeform.note = this.bill_details.bill_details.note
                this.typeform.discount = this.bill_details.bill_details.discount
                this.typeform.waived_off_amount = this.bill_details.bill_details.waived_off_amount
                this.typeform.paid_to = this.getPaidTo(this.bill_details.bill_details.paid_to)
                this.typeform.payment_status = this.getPaymentStatus(this.bill_details.bill_details.payment_status)
                this.typeform.payment_method = this.getPaymentMethod(this.bill_details.bill_details.payment_method)
                this.typeform.transaction_id = this.bill_details.bill_details.transaction_id
                this.payment_pending = false
            }
        },

        hide() {
            this.$emit('hide', true)
            this.$refs.modal2.hide();
        },

        clearData() {
            this.is_btn_spinner = false
        },

        receiveData(bill_details) {
            this.bill_details = bill_details
        },

        update_total_payable() {
            this.total_receviable_amount = parseFloat(0)
            if(this.typeform.payment_of) {
                if(this.typeform.payment_of.id == "0") {
                    this.total_receviable_amount = parseFloat(this.bill_details.bill_details.total_payable_mount)
                } else {
                    this.bill_details.short_summary.forEach((el,i)=>{
                        if(this.typeform.payment_of.id == "1" && (el.particulars == "OPD Charges" || el.particulars== "IPD Charges")){
                            this.total_receviable_amount = parseFloat(this.total_receviable_amount) + parseFloat(el.amount)
                        }
                        if(this.typeform.payment_of.id == "2" && el.particulars== "Reports"){
                            this.total_receviable_amount = parseFloat(this.total_receviable_amount) + parseFloat(el.amount)
                        }
                        if(this.typeform.payment_of.id == "3" && (el.particulars== "Treatment Medicines" || el.particulars== "OPD Medicines")){
                            this.total_receviable_amount = parseFloat(this.total_receviable_amount) + parseFloat(el.amount)
                        }
                    });
                }
            } else {
                this.total_receviable_amount = parseFloat(this.bill_details.bill_details.total_payable_mount)
            }
            this.actual_receviable_amount = parseFloat(this.total_receviable_amount) + parseFloat('0' + this.typeform.sgst) + parseFloat('0' + this.typeform.cgst) + parseFloat('0' + this.typeform.igst) - parseFloat('0' + this.typeform.discount) - parseFloat('0' + this.typeform.waived_off_amount)
        },

        receiveAmount() {

            if(!this.bill_details.bill_details.billing_no) {
                this.showMessage("Bill Not Found")
            } else if(!this.typeform.payment_of) {
               this.showMessage("Select Payment For")
           } else if(!this.typeform.cgst) {
               this.showMessage("Enter CGST")
           } else if(!this.typeform.sgst) {
               this.showMessage("Enter SGST")
           } else if(!this.typeform.igst) {
               this.showMessage("Enter IGST")
           } else if(!this.typeform.note) {
               this.showMessage("Enter Notes")
           } else if(!this.typeform.paid_to) {
               this.showMessage("Select Paid To")
           } else if(!this.typeform.bill_date) {
               this.showMessage("Select Payment Date")
           } else if(!this.typeform.payment_status) {
               this.showMessage("Select Payment Status")
           } else if(!this.typeform.payment_method) {
               this.showMessage("Select Payment Method")
           } else if(this.typeform.payment_method &&
                    (this.typeform.payment_method.id != '0' && !this.typeform.transaction_id)) {
               this.showMessage("Please enter transaction id")
           } else {
            Swal.fire({
                          title: string.CONFIRM,
                          text: string.MSG_CONFIRM_PAYEMNT_RECEIVED,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, Received"
                        }).then((result) => {
                          if (result.isConfirmed) {
                            var bodyFormData = new FormData();
                            bodyFormData.append('bill_id', this.bill_details.bill_details.billing_no);
                            bodyFormData.append('billing_date', moment(this.typeform.bill_date).format('YYYY-MM-DD'));
                            bodyFormData.append('amount', this.actual_receviable_amount);
                            bodyFormData.append('payment_status', this.typeform.payment_status.id);
                            bodyFormData.append('note', this.typeform.note);
                            bodyFormData.append('discount', this.typeform.discount);
                            bodyFormData.append('sgst', this.typeform.sgst);
                            bodyFormData.append('cgst', this.typeform.cgst);
                            bodyFormData.append('igst', this.typeform.igst);
                            bodyFormData.append('payment_method', this.typeform.payment_method.id);
                            bodyFormData.append('transaction_id', this.typeform.transaction_id);
                            bodyFormData.append('waived_off_amount', this.typeform.waived_off_amount);
                            bodyFormData.append('payment_of_transaction', this.typeform.payment_of.id);
                            bodyFormData.append('paid_to', this.typeform.paid_to.id);
                            this.payPatientBillingData(bodyFormData).then((response) => {
                                if (response.response_code == 200) {
                                    this.$toasted.success(response.message, {duration: 2000,});
                                    this.hide()
                                } else{
                                    this.$toasted.error(response.message, {duration: 2000,});
                                }
                            });
                          }
                        });
            }
        },
        showMessage(message) {
            this.$toasted.error(message, {duration: 2000,});
        },

        getPaymentStatus(payment_status_id) {
            return this.payment_status_list[parseFloat(payment_status_id)]
        },

        getPaymentMethod(payment_method_id) {
            return this.payment_method_list[parseFloat(payment_method_id)]
        },

        getPaidTo(paid_to_id) {
            return this.paid_to_list[parseFloat(paid_to_id)]
        },
         disableDatesAfterMax(date) {
          return date > new Date();
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>